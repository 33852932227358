<template>
  <div>
    <!--加载花瓣-->
    <div id="spinner"></div>
    <!--遮罩-->
    <div id="mask"></div>
    <!--页面内容-->
    <div class="container-fluid" id="container">
      <div id="header">
        <div class="pull-left" id="title">
          <img src="../../public/resources/images/title.png" />
        </div>
        <div class="pull-right" id="login" @click="logOff()">
          <img src="../../public/resources/images/close.png" />
        </div>
        <div id="line"></div>
        <div class="pull-right" id="info">
          <img :src="user.avatarName ? baseApi + '/avatar/' + user.avatarName : '../../public/resources/images/head.png'" class="user-avatar">
          <div style="margin-left: 15px">
            <p
              style="font-size: 16px; font-family: 'Source Han Sans CN Regular'"
            >
              {{ this.user.nickName }}
            </p>
          </div>
          <img
            src="../../public/resources/images/icon_open.png"
            style="width: 12px; margin-left: 15px"
          />
        </div>
      </div>
      <div id="content" class="container">
        <div id="nav">
          <template  v-for="(val, index) in navInfo">
            <button
              v-if="val.name"
              :class="{ active: val.isActive }"
              :key="index"
              :data-key="index"
              :data-id="val.id"
              @click="changeTabs($event,val)"
            >
              {{ val.name }}
            </button>
          </template>
          
        </div>
        <div>
          <div class="col-sm-4" v-for="(subject,index) in subjectList" :key="index">
            <div
              class="card"
              @click="fnClick(level, subject.id, subject.name)"
              v-if="level != 4"
            >
              <div
                class="itemImg"
                :class="'uncover'"
                :style="'background-image:url(\'' + handleThumbnail(subject.thumbnail) + '\')'"
              >
                <div v-if="level == 2 || level ==3" class="chapterTip">
                  <span class="chapterSpan">{{ subject.number }}</span>
                </div>
              </div>
              <div class="itemTitle">
                <span>{{ subject.name }}</span>
              </div>
            </div>
            <a :href="handleLink(subject.linkType,subject.link)" :target="'_blank'" v-else>
              <div class="card" @click="fnClick(level, subject.id, subject.name)">
                <div
                  class="itemImg"
                  :class="'uncover'"
                  :style="'background-image:url(\'' + resourceIcons[subject.extension] + '\')'"
                ></div>
                <div class="itemTitle">
                  <span>{{ subject.name }}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div id="footer">
        <img src="../../public/resources/images/b_logo.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {list as getSubjectList} from '@/api/subject.js'
import {list as getResourceRelevanceList} from '@/api/resourceRelevance.js'
import {add as addSubjectLog } from '@/api/subjectLog.js'
import {add as addStudyHistory } from '@/api/studyHistory.js'
export default {
  name: "Main",
  data() {
    return {
      level: 1, //层级信息（1：课程；2：章；3：节；4：资源）
      // navInfo: {
      //   1: {id:'', name: "课程",level: 1, isActive: true },

      // }, 
      navInfo: [ {id:'', name: "课程",level: 1, isActive: true }],
      resourceIcons: [],
      subjectList:[]
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'baseApi'
    ])
  },
  methods: {
    loadData(){
      this.getResourceIcons()
      this.getSubjectList(1)
    },
    getResourceIcons(){
      this.resourceIcons = []
      this.resourceIcons['pdf'] = './resources/images/icon_pdf.png'
      this.resourceIcons['ppt'] = './resources/images/icon_ppt.png'
      this.resourceIcons['pptx'] = './resources/images/icon_ppt.png'
      this.resourceIcons['doc'] = './resources/images/icon_doc.png'
      this.resourceIcons['docx'] = './resources/images/icon_doc.png'
      this.resourceIcons['mp4'] = './resources/images/icon_mp4.png'
      this.resourceIcons['unity'] = './resources/images/icon_unity.png'
    },
    getSubjectList(level,id){
      if(level === 1) { //课程
        this.openLoading()
        getSubjectList().then((res)=>{
        this.closeLoading()
        this.subjectList = res.content.map((val)=>{
          return {'id':val.subjectId,'name':val.subjectName,'level':1,'thumbnail':val.subjectThumbnail,'number':''}
        })
      }).catch(err=>{console.error(err)})
      }else if(level === 2) { // 章
        this.openLoading()
        this.handleLog(id)
        getSubjectList({parentId:id}).then((res)=>{
          this.closeLoading()
          this.subjectList = res.content.map((val)=>{
            return {'id':val.subjectId,'name':val.subjectName,'level':2,'thumbnail':val.subjectThumbnail,'number':val.subjectNumber}
          })
        }).catch(err=>{console.error(err)})
      }else if(level === 3) { //节
        this.openLoading()
        this.handleLog(id)
        getSubjectList({parentId:id}).then((res)=>{
          this.closeLoading()
          this.subjectList = res.content.map((val)=>{
            return {'id':val.subjectId,'name':val.subjectName,'level':3,'thumbnail':val.subjectThumbnail,'number':val.subjectNumber}
          })
        }).catch(err=>{console.error(err)})
      }else if(level === 4) { //资源
        this.openLoading()
        this.handleLog(id)
        getResourceRelevanceList({subjectId:id,subjectType:3}).then((res)=>{
          this.closeLoading()
          this.subjectList = res.content.map((val)=>{
            return {
              'id':val.resource.resourceId,
              'name':val.resource.resourceName,
              'level':3,
              'thumbnail':val.resource.resourceThumbnail,
              'number':'',
              'linkType':val.resource.resourceType,
              'link':val.resource.resourceLink,
              'extension':val.resource.resourceExtension
            }
          })
        }).catch(err=>{console.error(err)})
      }
    },
    handleLog(subjectId){
      addSubjectLog({subjectId}).then(()=>{}).catch(err=>console.log(err))
      addStudyHistory({subjectId}).then(()=>{}).catch(err=>console.log(err))
    },
    handleThumbnail(thumbnail){
      if(thumbnail) return this.baseApi + thumbnail
      return './resources/images/icon2.png'
    },
    handleLink(linkType,link){
      if(linkType === 1 || linkType === 2){
        return this.baseApi + link
      }else{
        return link
      }
    },
    //点击章节后获取章节内容 level:点击的层级；id:点击内容id；name:点击内容名称
    fnClick(currentlevel, id, name) {
      //由于点击的是上一层，需要获取下一层的信息，所以level需要+1
      let level = currentlevel + 1
      
      this.getSubjectList(level,id)
      if(currentlevel < 4)
        this.fnClickAction(level, name, id);
    },
    fnClickAction(level, name, id) {

      this.navInfo = this.navInfo.toSpliced(level - 1)
      for (const nav of this.navInfo) {
        nav.isActive = false
      }
      this.navInfo.push({id: id, name: name,level: level, isActive: true });
      this.level = level;
    },

    changeTabs(event,{level,id,name}) {
      this.fnClick(level - 1, id, name);
    },

    logOff() {
      this.$store
        .dispatch("LogOut").then(()=>location.reload()).catch(() => { });
    },
  },
  mounted() {
    this.loadData()
  },
};
</script>
  
<style>
html,
body {
  margin: 0;
  height: 100%;
  background-color: #f0f7fd;
}
a:hover,
a:active {
  text-decoration: none;
}
#mask {
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 1200;
}
#header {
  width: 100%;
  height: 100px;
  background-image: url("../../public/resources/images/top.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-attachment:fixed; */
  background-attachment: inherit;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}
#header > div {
  display: inline-block;
}
#title > img {
  width: 100%;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 5px;
}
#info {
  width: 200px;
  text-align: center;
  margin-top: 20px;
}
#info > div {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  color: #ffffff;
  margin-left: 5px;
}
#info p {
  margin-bottom: 0;
}
.user-avatar{
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
#login {
  width: 100px;
  height: 40px;
  text-align: center;
  /* border-left:1px solid #f0f0f1; */
  margin-top: 30px;
}
#content {
  width: 80%;
  margin: auto;
  margin-top: 120px;
  margin-bottom: 60px;
}
#nav {
  /* height:60px; */
  background-color: #ffffff;
  margin: 10px;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
#nav button {
  margin: 13px 0 13px 16px;
  color: #9ea7b1;
  border: 1px solid #dee1e4;
  display: inline-block;
  padding: 8px 24px;
  font-size: 15px;
  font-family: "Source Han Sans CN Regular";
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 6px;
}
#nav button.active {
  background-color: #4f78e0;
  color: #ffffff;
}
#footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
#footer img {
  width: 100%;
}
.col-sm-4 {
  padding-left: 0;
  padding-right: 0;
}
.card {
  min-height: 400px;
  margin: 10px;
  /* border:1px solid transparent; */
  border-radius: 10px;
  /* background-color: #ffffff; */
  box-shadow: 2px 2px 15px #dfe5eb;
}
.itemImg {
  width: 100%;
  height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px 10px 0 0;
}
.itemImg.uncover {
  background-size: inherit;
  background-color: #eaeaea;
}
.itemTitle {
  font-family: "Source Han Sans CN Bold";
  height: 80px;
  text-align: center;
  /* line-height: 80px; */
  color: #4f78e0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: Center;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  word-break: break-all;
}
/* 章节 */
.chapterTip {
  background-image: url(../../public/resources/images/chapter.png);
  width: 94.5px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35px;
  position: absolute;
  top: 8%;
  display: flex;
  justify-content: center;
  align-items: Center;
}
.chapterSpan {
  color: #ce0d52;
  font-size: 16px;
  font-family: "Source Han Sans CN Bold";
  margin-left: 15px;
}
</style>