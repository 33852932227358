<template>
  <div>
    <!--加载花瓣-->
    <div id="spinner"></div>
    <!--页面内容-->
    <div class="container-fluid" id="container">
      <div id="loginBox">
        <img src="../../public/resources/images/loginBox.png" />
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" @keyup.enter.native="fnSubmit">
          <el-form-item prop="username">
            <img src="../../public/resources/images/account.png" />
            <input
              type="text"
              placeholder="请输入账号"
              id="username"
              v-model="loginForm.username"
            />
          </el-form-item>
          <el-form-item prop="password">
            <img src="../../public/resources/images/passward.png" />
            <input
              type="password"
              placeholder="请输入密码"
              id="password"
              v-model="loginForm.password"
            />
          </el-form-item>
          
          <el-form-item>
            <el-button class="btn btn-primary" id="submit" @click="fnSubmit">
            登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "login",
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        rememberMe: true,
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
      },
    };
  },
  methods: {
    fnSubmit() {
      this.$refs.loginForm.validate(valid => {
        if(valid){

          const user = {
            username: this.loginForm.username,
            password: this.loginForm.password,
            rememberMe: this.loginForm.rememberMe,
          };

          this.openLoading()
          this.$store
            .dispatch("Login", user)
            .then(() => {
              this.closeLoading()
              this.$router.push({ path: this.redirect || "/" })
            })
            .catch((err) => {
              console.log(err);
              this.closeLoading()
            });

        } else {
          console.log('error submit!!')
          return false
        }
      })

      // url为后端对接接口。参数（account,password）。
      // this.common.fnGetdata(url,{account:this.account,password:this.password},'POST',true,3000,this.fnGet,this)
      //this.$router.push({ name: "Main" });
    },
    
  },
};
</script>
  
<style scoped>
html,
body {
  margin: 0;
  height: 100%;
}
input {
  outline: none;
}
#container {
  background-image: url("../../public/resources/images/loginBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}
#loginBox {
  width: 1000px;
  margin: auto;
  position: relative;
  top: 150px;
  height: 570px;
}
#loginBox > img {
  width: 100%;
  position: absolute;
  z-index: 1;
}
#loginBox > form {
  position: absolute;
  z-index: 99;
  top: 30%;
  left: 5%;
  width: 40%;
}
#loginBox > form > div > div {
  margin: 20px;
  position: relative;
  width: 100%;
}
#loginBox > form > div > div > img {
  position: absolute;
  left: 10px;
  top: 18px;
  width: 16px;
}
#username,
#password {
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 34px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #22252c;
  font-family: "source Han Sans CN Ragular";
  /* background-color: #f7f8ff; */
  background-image: url("../../public/resources/images/input.png");
  border: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
#submit {
  margin: 20px;
  width: 100%;
  height: 60px;
  background-color: #4f78e0;
  color: #ffffff;
  font-size: 22px;
  font-family: "source Han Sans CN Bold";
  border: 1px solid #4f78e0;
  border-radius: 5px;
}
#submit:hover {
  background-color: #3b5fbd;
}
#submit:checked {
  background-color: #5e8cff;
}
input:focus {
  border: 1px solid #ccc;
}
input::-webkit-input-placeholder {
  color: #c3c7d1;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c3c7d1;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c3c7d1;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c3c7d1;
}
</style>