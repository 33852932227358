import { Loading } from "element-ui";

const options = {
  lock: true,
  text: "Loading",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.7)",
};

export const openLoading = function () {
  let loading = Loading.service(options);
  setTimeout(() => {
    this.$nextTick(() => {
      // 以服务的方式调用的 Loading 需要异步关闭
      loading.close();
    });
  }, 10000);
  return loading;
};

export const closeLoading = function () {
  let loading = Loading.service(options);
  this.$nextTick(() => {
    // 以服务的方式调用的 Loading 需要异步关闭
    loading.close();
  });
};

export default { openLoading ,closeLoading};
