import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import store from './store'

import {openLoading,closeLoading} from './utils/loading';

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.openLoading = openLoading
Vue.prototype.closeLoading = closeLoading

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')